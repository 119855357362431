<template>
  <div class="counter">
    <h1>Counter</h1>
    <div class="watchBand">
    <div class="digit" style="left:182px;top:60px;transform: skew(330deg, 2deg) scale(0.9);">{{countText.slice(0,1)}}</div>
    <div class="digit" style="left:165px;top:93px;transform: skew(335deg, 3deg) scale(0.95);">{{countText.slice(1,2)}}</div>
    <div class="digit" style="left:152px;top:127px;transform: skew(341deg, 0deg) scale(1);">{{countText.slice(2,3)}}</div>
    <div class="digit" style="left:141px;top:165px;transform: skew(346deg, 2deg) scale(1.05,1.05);">{{countText.slice(3,4)}}</div>
    <div class="digit" style="left:135px;top:202px;transform: skew(354deg, 5deg) scale(1.0,1.1);">{{countText.slice(4,5)}}</div>
    <div class="digit colm" style="left:132px;top:239px;transform: skew(358deg, 6deg) scale(1,1.1);">{{countText.slice(5,6)}}</div>
    <div class="digit colm" style="left:132px;top:276px;transform: skew(3deg, 6deg) scale(1,1.1);">{{countText.slice(6,7)}}</div>
    <div class="digit colm" style="left:136px;top:315px;transform: skew(6deg, 8deg) scale(1,1.1);">{{countText.slice(7,8)}}</div>

    </div>
  
    <h1>{{count}} meters</h1>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  
  },
  data() {
    return {
      count:0,
      countText:"0",
      startTime:null

    }
  },
  mounted(){
    this.startTime=new Date()
    window.requestAnimationFrame(this.tick);
  },
  methods:{
    tick(){
      this.count=Math.floor(((new Date()-this.startTime)/1000)*1670*1000/60/60)
      if(this.count>40075000){
        this.count=0
      }
      //this.count=0
      this.countText=("00000000"+this.count).slice(-8)
      window.requestAnimationFrame(this.tick);
      
    }
  }

}
</script>
<style scoped>
@font-face {
  font-family: "Repetition Scrolling";
  src: local("Repetition  Scrolling"),
   url(../assets/fonts/repet.ttf) format("truetype");
}
.digit{
  position:absolute;
  text-align: center;
  font-size:40px;
  color:#ffbc32;
  opacity: 0.8;
  font-family:"Repetition Scrolling";
}
.watchBand{
  position:relative;
  width:500px;
  height:500px;
  background-image:url(../assets/images/Band2.png);
  background-size:cover;
  margin: auto;
}
.colm{
  color:#faeda4;
}
</style>